<template>
  <footer class="footer">
    <div class="container">
      <div class="link-wrapper">
        <a :href="`https://www.stoecklin.com/${imprintUrl}`" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <a :href="`https://www.stoecklin.com/${imprintUrl}`" target="_blank" rel="noopener noreferrer">Terms of Service</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    imprintUrl() {
      // switch case for different languages
      switch (this.currentLanguage) {
        case "de":
          return "de/impressum";
        case "en":
          return "imprint";
        case "fr":
          return "fr/mentions-legales";
        case "it":
          return "it/impronta";
        case "es":
          return "es/pie-de-imprenta";
        default:
          return "imprint";
      }
    },
  }
};

</script>

<style lang="scss">
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1005;

  .link-wrapper {
    padding-bottom: 20px;
    padding-left: 20px;

    a {
      color: #333;
      margin-right: 20px;
      text-decoration: none;
      font-size: 14px;
      transition: color .125s linear;

      &:hover {
        color: #182983;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1024px) {
    .footer {
      padding-bottom: 0;
    }
  }
}
</style>
