import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import App from './App.vue'
import './utils/customEvents';

import en from './locales/en.json';
import de from './locales/de.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import it from './locales/it.json';
import nl from './locales/nl.json';

import 'shepherd.js/dist/css/shepherd.css';
import './styles/base.scss';

const messages = {
    en,
    de,
    fr,
    es,
    it,
    nl,
};

const i18n = createI18n({
    locale: 'en', // Standard-Sprache
    fallbackLocale: 'en',
    messages,
});

const app = createApp(App);
app.use(i18n);
app.mount('#app');
